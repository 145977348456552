import React, { useEffect } from "react"
import { acceptedAudience } from "../../services/global/cookiesService"

const CywycChat = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && acceptedAudience()) {
      let tag = document.createElement("script")
      tag.async = true
      tag.type = "text/javascript"
      tag.src = `${
        document.location.protocol
      }//ws.cywyc.fr/chat/5c9c868ccffb8718e0152364/${new Date().getTime()}`
      document.getElementsByTagName("head")[0].appendChild(tag)
    }
  }, [])

  return null
}

export default CywycChat
