import { faSadTear } from "@fortawesome/free-regular-svg-icons"
import { MgcgestionTokenService } from "../mgcgestionTokenService"


const ATINTERNET_EXEMPT='exempt'
const ATINTERNET_OPTIN='optin'
const ATINTERNET_OPTOUT='optout'
const ATINTERNET_CNIL=ATINTERNET_EXEMPT
const USER_ID='user_id'
const USER_CATEGORY='user_category'
const CATEGORY_CONNECTED='CONNECTE'

const CLICK_NAVIGATION='click.navigation'
const CLICK_ACTION='click.action'
const CLICK_EXIT='click.exit'
const CLICK_DOWNLOAD='click.download'
const SEARCH_DISPLAY='internal_search_result.display'
const SEARCH_CLICK='internal_search_result.click'

class ATInternetService {
    constructor() {
    /*    this.tag = this.newTag();
        if (this.tag && this.tag.privacy) {
          let mode = this.tag.privacy.getVisitorMode()
          //console.log('mode = '+mode)
          if (mode == undefined || !mode) {
            this.setMode(ATINTERNET_EXEMPT);
          }
          console.log("ATInternetService Created")
        }
     */
    }
    newTag(options) {
        try {
          /*if (typeof window == "undefined") {
            //console.log('No WIndow')
            return new ATInternet.Tracker.Tag(options);
          } else {
            //console.log('WIndow exists')
            return  new window.ATInternet.Tracker.Tag(options);
          }*/
          return  new window.ATInternet.Tracker.Tag(options);
        } catch(ex) {
        console.log("AT-INTERNET FAILED")
        return {
            dispatch: () => ({}),
            page: { set: () => ({}) },
            click: { send: () => ({}) }
        };
      }
    }
    hashTheID(id) {
      return this.sha256(id);
    }
    setUserData() {
      let email = MgcgestionTokenService.getAuthEmail()
      if (email) {
        this.tag.setProps({USER_ID:this.hashTheID(email),USER_CATEGORY:CATEGORY_CONNECTED})
      }
    }
    _rewrite(val) {
      if (typeof val === 'number') {
        return val
      }
      let s = ''+val;
      s = s.toLowerCase()
      s = s.replace(/\x20/g,'_').replace(/(,\.;\?&)/g,'').replace(/'/g,'').replace(/"/g,'').replace(/-/g,'_')
      s = s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return s;
    }
    reWriteInfo(info) {
      let ret = {}
      for (let key in info) {
        ret[key] = this._rewrite(info[key])
      }
      console.log(ret)
      return ret
    }
    sendUrledPage(url,info) {
      info.page = this.getPageUrl(url)
      this.sendPage(info)
    }
    sendPageFromMetadata(meta) {
      let page = this.getPageUrl(meta.uri)
      let chapter = meta.pathContext.page.chapter
      let info
      if (!chapter || chapter === '') {
        info = {page: page}
      } else {
        info = {page: page,page_chapter1: chapter}
      }
      this.sendPage(info)
    }
    sendUrledPageAndChapter1(url) {
      let page = this.getPageUrl(url);
      let chapter = this.getPageChapter(url)
      if (chapter === '') {
        chapter = page
      }
      let info = {page: page,page_chapter1: chapter}
      this.sendPage(info)
    }
      //@param info: {name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, customObject?: any}
    sendPage(info) {
        /*console.log('AT page')
        console.log(info)*/
        if (this.tag == undefined || this.tag.privacy == undefined) {
          return;
        }
        //console.log(this.tag.privacy.getVisitorMode())
        this.tag.delProps();
        this.tag.setProps(this.reWriteInfo(info),true);
        if (info.page) {
          this.tag.page.set({name: this._rewrite(info.page)});
        }
        this.setUserData();
        this.tag.dispatch();
        console.log("ATINTERNET page "+info.page);
    }
    //@param info: {elem: any, name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, type: string, customObject?: any}
    sendClick(name,info) {
      /*console.log('AT click ')
      console.log(name)
      console.log(info) */
      if (this.tag == undefined || this.tag.privacy == undefined) {
        return;
      }
      /*console.log(this.tag.privacy.getVisitorMode())
      console.log('---before---')
      console.log(name) */
      info = this.reWriteInfo(info)
      /*console.log(info) */
      this.tag.events.send(name,info);
      /*console.log('---after---') */
      console.log("ATINTERNET click "+name);
    }

    sendNavClick(info) {
      console.log(info.click);
      console.log('sendNavClick');
      if(info.click==='adhesion'){
        console.log("cta_adhesion");
        window.dataLayer.push({
          event: "cta_adhesion"
        });
      } else if(info.click==='demande_de_devis'){
        console.log("demande_de_devis");
        window.dataLayer.push({
          event: "cta_demande_devis"
        });
      } else if(info.click==='Espace_courtier' || 
                info.click==='Espace_comite_local' || 
                info.click==='Espace_professionnel_de_sante' || 
                info.click==='Espace_adherent' || 
                info.click==='Espace_employeur') {
        //Espace_comite_local
        //Espace_professionnel_de_sante
        //Espace_courtier
        //Espace_adherent
        //Espace_employeur
        console.log("connexion");
        window.dataLayer.push({
          event: "click_connexion",
          area_name: info.click
        });
      
      } else {
        console.log("click_stickers");
        window.dataLayer.push({
          event: "click_stickers",
          sticker_name: info.click
        });
      }
      console.log('sendNavClick');
      this.sendClick(CLICK_NAVIGATION,info)
    }

    sendActClick(info) {
      this.sendClick(CLICK_ACTION,info)
    }
    sendExitClick(info) {
        console.log("sendExitClick");
        window.dataLayer.push({
        event: "click_social_media",
            social_media: info.click});
        console.log(info.click);
        this.sendClick(CLICK_EXIT,info)
    }
    sendDnClick(info) {
      this.sendClick(CLICK_DOWNLOAD,info)
    }
    sendYoutubeClick(faq) {
      let titre = 'Inconnu'
      let debut = faq.content.search('title')
      if (debut !== -1) {
        let chaine = faq.content.substring(debut)
        debut = chaine.search('\"')
        if (debut !== -1) {
          chaine = chaine.substring(debut+1)
          let fin = chaine.search('\"')
          if (fin !== -1) {
            titre = chaine.substring(0,fin)
          }
        }
      }
      let info = {click: 'video_youtube',
        click_chapter1: titre,
        click_chapter2: faq.title
      }
      this.sendActClick(info)
    }
    sendSearchDisplayClick(info) {
      this.sendClick(SEARCH_DISPLAY,info)
    }
    sendSearchClick(info) {
      this.sendClick(SEARCH_CLICK,info)
    }
    setMode(mode) {
      console.log(`AT-INTERNET change mode ${mode}`);
      if (!this.tag || !this.tag.privacy) {
        console.log(`AT-INTERNET setMode with tag or tag.privacy undefined -> exit`);
        return;
      }
      if (mode === ATINTERNET_OPTIN) {
        this.tag.privacy.setVisitorOptin();
      } else if (mode === ATINTERNET_OPTOUT) {
        this.tag.privacy.setVisitorOptout();
      } else {
        this.tag.privacy.setVisitorMode('cnil', 'exempt');
      }
    }
    setOptinMode() {
      this.setMode(ATINTERNET_OPTIN)
    }
    setOptoutMode() {
      this.setMode(ATINTERNET_OPTOUT)
    }
    setCnilMode() {
      this.setMode(ATINTERNET_EXEMPT)
    }
    setModeByUI(uiData) {
      if (uiData.state) {
        this.setOptinMode()
      } else if (uiData.subState) {
        this.setCnilMode()
      } else {
        this.setOptoutMode();
      }
    }
    getUIMode() {
      let mode = this.tag.privacy.getVisitorMode()
      if (!mode) {
        return {state:false,subState:true}
      }
      if (mode.name === ATINTERNET_OPTIN) {
        return {state:true,subState:true}
      } else if (mode.name === ATINTERNET_OPTOUT) {
        return {state:false,subState:false}
      } else {
        return {state:false,subState:true}
      }

    }
    _getPageUrlAsArray(_url) {
      if (typeof window !== "undefined") {
        let url = _url
        if (!url) {
          url = window.location.href;
        }
        if (!url || !url.length) {
            return null;
        }
        if (url[url.length-1] == '/') {
            url = url.substring(0,url.length-1)
        }
        let ar = url.split('/')
        return ar;
      } else {
        return [];
      }
    }
    getPageUrl(url) {
      let ar = this._getPageUrlAsArray(url)
      if (ar && ar.length > 1) {
        return ar[ar.length-1];
      } else {
        return '';
      }
    }
    getPageChapter(url) {
      let ar = this._getPageUrlAsArray(url)
      if (ar && ar.length > 2) {
        return ar[ar.length-2];
      } else {
        return '';
      }
    }
    isThereYoutubeVideo(faqContent) {
      return (faqContent.indexOf('id=\"player\"') === -1) ? false:true
    }
    //récupérée sur internet
    sha256(ascii) {
      function rightRotate(value, amount) {
        return (value>>>amount) | (value<<(32 - amount));
      };
      
      let mathPow = Math.pow;
      let maxWord = mathPow(2, 32);
      let lengthProperty = 'length'
      let i, j; // Used as a counter across the whole file
      let result = ''
    
      let words = [];
      let asciiBitLength = ascii[lengthProperty]*8;
      
      //* caching results is optional - remove/add slash from front of this line to toggle
      // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
      // (we actually calculate the first 64, but extra values are just ignored)
      let hash = this.sha256.h = this.sha256.h || [];
      // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
      let k = this.sha256.k = this.sha256.k || [];
      let primeCounter = k[lengthProperty];
      /*/
      let hash = [], k = [];
      let primeCounter = 0;
      //*/
    
      let isComposite = {};
      for (let candidate = 2; primeCounter < 64; candidate++) {
        if (!isComposite[candidate]) {
          for (i = 0; i < 313; i += candidate) {
            isComposite[i] = candidate;
          }
          hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
          k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
        }
      }
      
      ascii += '\x80' // Append Ƈ' bit (plus zero padding)
      while (ascii[lengthProperty]%64 - 56) ascii += '\x00' // More zero padding
      for (i = 0; i < ascii[lengthProperty]; i++) {
        j = ascii.charCodeAt(i);
        if (j>>8) return; // ASCII check: only accept characters in range 0-255
        words[i>>2] |= j << ((3 - i)%4)*8;
      }
      words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
      words[words[lengthProperty]] = (asciiBitLength)
      
      // process each chunk
      for (j = 0; j < words[lengthProperty];) {
        let w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
        let oldHash = hash;
        // This is now the undefinedworking hash", often labelled as variables a...g
        // (we have to truncate as well, otherwise extra entries at the end accumulate
        hash = hash.slice(0, 8);
        
        for (i = 0; i < 64; i++) {
          let i2 = i + j;
          // Expand the message into 64 words
          // Used below if 
          let w15 = w[i - 15], w2 = w[i - 2];
    
          // Iterate
          let a = hash[0], e = hash[4];
          let temp1 = hash[7]
            + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
            + ((e&hash[5])^((~e)&hash[6])) // ch
            + k[i]
            // Expand the message schedule if needed
            + (w[i] = (i < 16) ? w[i] : (
                w[i - 16]
                + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3)) // s0
                + w[i - 7]
                + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10)) // s1
              )|0
            );
          // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
          let temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
            + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2])); // maj
          
          hash = [(temp1 + temp2)|0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
          hash[4] = (hash[4] + temp1)|0;
        }
        
        for (i = 0; i < 8; i++) {
          hash[i] = (hash[i] + oldHash[i])|0;
        }
      }
      
      for (i = 0; i < 8; i++) {
        for (j = 3; j + 1; j--) {
          let b = (hash[i]>>(j*8))&255;
          result += ((b < 16) ? 0 : '') + b.toString(16);
        }
      }
      return result;
    }    
  }

  export let ATInternetTagService = new ATInternetService();
