import React, { useState } from 'react';

const ToggleMenu = ({open, onClose, isSmall}) => {

  let marginRight = '0';
  if (open) {
    marginRight = isSmall ? '562px' : '385px';
  }
  const style = {
    marginRight: marginRight
  }

  const [menuActif, setMenuActif] = useState(0);
  /*
  je clique sur un menu comportant un sous menu :
  -s'il y en a un d'actif(ouvert, soit numMenu!= 0): je le désactive
  -j'active le nouveau(ouvrir)
  */
  function activeSousMenu(numMenu) {
    if (menuActif == numMenu) {
      setMenuActif(0);
    }
    else {
      setMenuActif(numMenu);
    }
  }

  return (
  <div className="sidenav" style={style}>
    <ul>
      <li onClick={onClose}>Menu<i className="icon-close hideMenu" /></li>
      <li><a href="#" onClick={()=>activeSousMenu(1)}>Cheminot<i className={menuActif == 1 ? "fa fa-chevron-down":"fa fa-chevron-right"}/></a>
        {menuActif == 1 &&
        <ul>
          <li><a href="/cheminot-en-activite/" class="mnuSousItem">Je suis en activité</a></li>
          <li><a href="/cheminot-retraite/" class="mnuSousItem">Je suis retraité</a></li>
        </ul>}
      </li>
      <li><a href="#" onClick={()=>activeSousMenu(2)}>Particulier<i className={menuActif == 2 ? "fa fa-chevron-down":"fa fa-chevron-right"}/></a>
        {menuActif == 2 &&
        <ul>
          <li><a href="/particulier/" class="mnuSousItem">J'ai moins de 60 ans</a></li>
          <li><a href="/senior/" class="mnuSousItem">J'ai 60 ans ou plus</a></li>
        </ul>}
      </li>
      <li><a href="#" onClick={()=>activeSousMenu(3)}>Agent territorial / Collectivité<i className={menuActif == 3 ? "fa fa-chevron-down":"fa fa-chevron-right"}/></a>
        {menuActif == 3 &&
        <ul>
          <li><a href="/agent-territorial/" class="mnuSousItem">Je suis agent territorial</a></li>
          <li><a href="/collectivite-territoriale/" class="mnuSousItem">Je suis une collectivité territoriale</a></li>
        </ul>}
      </li>
      <li><a href="#" onClick={()=>activeSousMenu(4)}>Indépendant / Entreprise<i className={menuActif == 4 ? "fa fa-chevron-down":"fa fa-chevron-right"}/></a>
        {menuActif == 4 &&
          <ul>
            <li><a href="/professionnel-independant/" className="mnuSousItem">Je suis un indépendant</a></li>
            <li><a href="/entreprise/" className="mnuSousItem">Je suis une entreprise</a></li>
            <li><a href="/entreprise-syntec/" className="mnuSousItem">Je suis une entreprise SYNTEC</a></li>
            <li><a href="/transport-routier/" className="mnuSousItem">Je suis une entreprise de transport</a></li>
          </ul>}
      </li>
      <li><a href="/actualites/">Actualités</a></li>
      <li><a href="/la-mutuelle-mgc/">La MGC</a></li>
      <li><a href="/faq/">FAQ</a></li>
      <li><a href="/contact/">Contact</a></li>
      <li><a href="/mutuelle-sante/">Mutuelle santé</a></li>
      <li><a href="/mutuelle-prevoyance/">Prévoyance</a></li>
      <li><a href="/espace-resiliation/">Résilier votre contrat</a></li>
    </ul>
  </div>
)
};

export default ToggleMenu
