import PropTypes from "prop-types"
import React, { useContext } from "react"
import { navigate } from "gatsby"
import logo from "../images/LOGO_MGC_2018.jpg"
import iconMgc from "../images/icon-mgc.png"
import classNames from "classnames"
import "./header.css"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/globalContextProvider"
import { ATInternetTagService } from '../services/global/ATInternetService';

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      searchValue: null,
    }

    this.submitSearch = this.submitSearch.bind(this)
    this.handleChangeSearch = this.handleChangeSearch.bind(this)
  }

  submitSearch(event, globalDispatch) {

    console.log("submitSearch")
    event.preventDefault()

    globalDispatch({ type: "TOGGLE_SEARCH" })

    window.dataLayer.push({
      event: "search",
      search_term: this.state.searchValue
    });

    navigate(`/recherchePage?recherche=${this.state.searchValue}&cat=home`)
  }

  handleChangeSearch(event) {
    this.setState({ searchValue: event.target.value })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { searchOpen } = this.props
    if (prevProps.searchOpen !== searchOpen && searchOpen) {
      if (!this.sInput.hidden) {
        this.sInput.focus()
      }
      if (!this.sInputMobile.hidden) {
        this.sInputMobile.focus()
      }
    }
  }

  handleSearchIconClick(event, globalDispatch) {
    const {searchOpen, clickSearch} = this.props;
/*
    console.log("aaaaaaaaaaaaaaaaaaaa")
    console.log(this.state.searchValue);
    console.log("cccccccccccccccccccc")

    window.dataLayer.push({
      event: "search",
      search_term: this.state.searchValue
    });
*/
    if (!searchOpen) {
      return clickSearch();
    }
    return this.submitSearch(event, globalDispatch);
  }

  render() {
    const {
      siteTitle,
      clickConnexion,
      clickMenu,
      clickSearch,
      searchOpen,
      home,
    } = this.props

    const headerFlexLogoDesktop = () => {
      if (home) {
        return (
          <h1 className="headerFlexLogoTextDesktop">
            <a href="/" className="siteTitle">
              Mutuelle MGC&nbsp;
              <div style={{ fontSize: "18px" }}>
                Santé & Prévoyance depuis 140 ans
              </div>
            </a>
          </h1>
        )
      } else {
        return (
          <div className="headerFlexLogoTextDesktop">
            <a href="/" className="siteTitle">
              Mutuelle MGC&nbsp;
              <div style={{ fontSize: "18px" }}>
                Santé & Prévoyance depuis 140 ans
              </div>
            </a>
          </div>
        )
      }
    }

    return (
      <GlobalStateContext.Consumer>
        {globalState => {
          const closeSearchClasses = classNames("fa", {
            "fa-times-circle": !globalState.searchInProgress,
            "fa-spinner fa-pulse": globalState.searchInProgress,
          })

          return (
            <GlobalDispatchContext.Consumer>
              {globalDispatch => {
                return (
                  <header className="header">
                    <div className="container">
                      <div className="headerFlex">
                        <div className="headerFlexLogo">
                          <a href="/">
                            <img
                              src={logo}
                              alt="Logo Mutuelle MGC"
                              className="headerFlexLogoDesktop"
                            />
                          </a>
                          {headerFlexLogoDesktop()}

                          <a href="/" className={""}>
                            <img
                              src={logo}
                              alt="Logo Mutuelle MGC mobile"
                              className="headerFlexLogoMobile"
                            />
                          </a>
                          <p className="headerFlexLogoTextMobile">
                            <a href="/" className="siteTitle">
                              Mutuelle MGC
                            </a>
                          </p>
                        </div>

                        {/* début nav version Desktop */}
                        <div className="headerFlexNavDesktop">
                          {/* debut du formulaire de recherche */}
                          <div className="wrapperDesktop">
                            <form
                              id="formSearchDesktop"
                              className={`icon-search-container text-left ${
                                searchOpen ? "active" : ""
                              }`}
                              onSubmit={e => {
                                this.submitSearch(e, globalDispatch)
                              }}
                            >
                              <button
                                id="buttonSearchDesktop"
                                className="fa fa-search my-auto"
                                onClick={(e) => this.handleSearchIconClick(e, globalDispatch)}
                                type="button"
                              />
                              <input
                                type="search"
                                className="search-input"
                                id="inputSearchDesktop"
                                placeholder="Rechercher"
                                onChange={this.handleChangeSearch}
                                ref={input => {
                                  this.sInput = input
                                }}
                              />
                              <span
                                className={closeSearchClasses}
                                id="closeSearchDesktop"
                                onClick={clickSearch}
                              />
                            </form>
                            <span
                              id="spanSearchDesktop"
                              className="spanSearch"
                              hidden={searchOpen}
                            >
                              Rechercher
                            </span>
                          </div>
                          {/* fin du formulaire de recherche */}
                          <div className="headerFlexNavDesktopItem">
                            <a href="/contact/" className="headerFlexLinks" onClick={() => ATInternetTagService.sendNavClick({click:'contact'})}>
                              <i className="icon-email" />
                              Contact
                            </a>
                          </div>
                          {/* On met ici le AT pour la page sur laquelle on arrive parce que la dite page est rafraichie en permanence et ça génère des dizaines de hits */}
                          <div className="headerFlexNavDesktopItem">
                            <a
                              href="#"
                              className="headerFlexLinks"
                              onClick={() => {ATInternetTagService.sendNavClick({click:'connexion'});ATInternetTagService.sendPage({page:'espace_personnel',page_chapter1 : 'connexion'});clickConnexion()}}
                            >
                              <i className="icon-connection showConnection" />
                              Connexion
                            </a>
                          </div>
                          <div className="headerFlexNavDesktopItem">
                            <a
                              href="/la-mutuelle-mgc/"
                              className="headerFlexLinks" onClick={() => ATInternetTagService.sendNavClick({click:'la_mgc'})}
                            >
                              <img src={iconMgc} alt="" />
                              La MGC
                            </a>
                          </div>
                          <div className="headerFlexNavDesktopItem">
                            <a
                              href="#"
                              className="headerFlexLinks"
                              onClick={() => {ATInternetTagService.sendNavClick({click:'menu'});clickMenu()}}
                            >
                              <i className="fas fa-bars showMenu" />
                              Menu
                            </a>
                          </div>
                        </div>
                        {/* fin nav version Desktop */}

                        {/* début nav version Mobile */}
                        <div className="headerFlexNavMobile">
                          {/* debut du formulaire de recherche */}
                          <div className={`wrapper  ${searchOpen && "active"}`}>
                            <form
                              className={`icon-search-container ${
                                searchOpen ? "active" : ""
                              }`}
                              data-ic-class="search-trigger"
                              onSubmit={e => {
                                this.submitSearch(e, globalDispatch)
                              }}
                            >
                              <button
                                className="fa fa-search"
                                type="button"
                                onClick={(e) => this.handleSearchIconClick(e, globalDispatch)}
                              />
                              <input
                                type="search"
                                className="search-input"
                                data-ic-class="search-input"
                                placeholder="Rechercher"
                                onChange={this.handleChangeSearch}
                                ref={input => {
                                  this.sInputMobile = input
                                }}
                              />
                              <span
                                className={closeSearchClasses}
                                data-ic-class="search-clear"
                                onClick={clickSearch}
                              />
                            </form>
                          </div>
                          {/* fin du formulaire de recherche */}
                          <div
                            className="headerFlexNavMobileItem mailicon"
                            hidden={searchOpen}
                          >
                            <a
                              href="/contact/"
                              className="headerFlexLinks headerFlexLinksContact"
                            >
                              <i className="icon-email" />
                            </a>
                          </div>
                          <div
                            className="headerFlexNavMobileItem"
                            hidden={searchOpen}
                          >
                            <a
                              href="#"
                              className="headerFlexLinks"
                              onClick={clickConnexion}
                            >
                              <i className="icon-connection showConnection" />
                            </a>
                          </div>
                          <div
                            className="headerFlexNavMobileItem"
                            hidden={searchOpen}
                          >
                            <a
                              href="#"
                              className="headerFlexLinks"
                              onClick={clickMenu}
                            >
                              <i className="fas fa-bars showMenu" />
                            </a>
                          </div>
                        </div>
                        {/* fin nav version Mobile */}
                      </div>
                    </div>
                  </header>
                )
              }}
            </GlobalDispatchContext.Consumer>
          )
        }}
      </GlobalStateContext.Consumer>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  clickConnexion: PropTypes.func,
  clickMenu: PropTypes.func,
  clickSearch: PropTypes.func,
  searchOpen: PropTypes.bool,
  home: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  home: false,
}

export default Header
