/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import ToggleMenu from "./toggle-menu"
import FloatingMenu from "./floating-menu"
import ToggleConnection from "./toggle-connection"
import SEO from "./seo"

import "../css/bootstrap.css"
import "../css/main.css"
import "../css/normalize.css"
import "../css/slick.css"
import "../css/fontawesome-5.8.2-all.css"
import "../css/webcallback.css"
import "../css/LnkWcb.css"
import "../css/LnkWcbPopin.css"
import "./toggle-connection.css"

import GAnalytics from "./global/gAnalytics"
import CookieBanner from "./global/cookieBanner"
/*
import {
  acceptedAds,
  deleteAdCookies,
  deleteAudienceCookies,
  hasCookieConsent
} from "../services/global/cookiesService"
*/
import urlService from "../services/global/urlService"
import CywycChat from "./global/cywycChat"
//import MicrosoftAdvertising from "./global/microsoftAdvertising"
//import CookieModal from "./global/cookieModal"
//import { acceptedAudience } from './../services/global/cookiesService';
//import { MgcgestionTokenService } from "./../services/mgcgestionTokenService"

class Layout extends React.Component {
  isMenuOpened() {
    return this.state.connexionMenuOpen || this.state.menuOpen
  }

  toggleConnexion() {
    this.setState({
      connexionMenuOpen: !this.state.connexionMenuOpen,
    })
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  closeMenuAndConnexion() {
    if (this.isMenuOpened()) {
      this.setState({
        connexionMenuOpen: false,
        menuOpen: false,
      })
    }
  }

  toggleSearch() {
    this.setState({
      searchOpen: !this.state.searchOpen,
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      isSmallWidth: false,
      connexionMenuOpen: false,
      menuOpen: false,
      searchOpen: false,
      //hasCookieConsent: true,
      //editCookies: false
    }

    this.isMenuOpened = this.isMenuOpened.bind(this)
    this.toggleConnexion = this.toggleConnexion.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.closeMenuAndConnexion = this.closeMenuAndConnexion.bind(this)
//    this.showCookieConsent = this.showCookieConsent.bind(this)
//    this.hideCookieConsent = this.hideCookieConsent.bind(this)
  }

  componentDidMount() {
    // Below this is where I'd like to use the media query to set the sidenavOPen to false. Just not sure how to achieve that
    this.checkWidth = () => {
      const match = window.matchMedia(`(max-width: 576px)`)
      this.setState({ isSmallWidth: match.matches })
    }

    this.checkWidth()
    window.addEventListener("resize", this.checkWidth)

    //this.setState({ hasCookieConsent: hasCookieConsent() })
    
/*
    if (!acceptedAds() || true) {
      deleteAdCookies()
    }

    if (!acceptedAudience || false) {
      deleteAudienceCookies()
    }
*/
    
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkWidth)
  }
/*
  showCookieConsent() {
    this.setState({ editCookies: true })
  }

  hideCookieConsent() {
    this.setState({ editCookies: false })
  }
*/

  render() {
    const {
      titleSeo,
      keywordsSeo,
      description,
      children,
      tracker,
      canonical,
      position,
      meta,
      home,
      faq,
    } = this.props

    if(typeof window !== "undefined"){
//    window.dataLayer = window.dataLayer || [];
//    function gtag(){window.dataLayer.push(arguments)}
//    window.gtag = gtag;
    }
//    gtag('js', new Date());
//    gtag('config', process.env.GATSBY_G_TRACKING_ID);



    const baseUrl =
      typeof window !== "undefined" &&
      urlService.addSlashToEndOfUrl(
        `${window.location.protocol}//${window.location.host}${window.location.pathname
        }`
      )
    const canonicalUrl = canonical || baseUrl

    const {
      searchOpen,
      connexionMenuOpen,
      isSmallWidth,
      menuOpen,
//      hasCookieConsent,
//      editCookies
    } = this.state

    const currentUrl =
      typeof window !== "undefined" &&
      window &&
      window.location.pathname + window.location.search

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>

            <SEO
              title={titleSeo}
              keywords={keywordsSeo}
              description={description}
              canonical={canonicalUrl}
              meta={meta}
              faq={faq}
            />
            <div
              className="overlay"
              id="overlay"
              style={{ display: this.isMenuOpened() ? "block" : "none" }}
              onClick={this.closeMenuAndConnexion}
            />
            <Header
              siteTitle={data.site.siteMetadata.title}
              clickConnexion={this.toggleConnexion}
              clickMenu={this.toggleMenu}
              searchOpen={searchOpen}
              clickSearch={this.toggleSearch}
              home={home}
            />
            <ToggleConnection
              open={connexionMenuOpen}
              onClose={this.toggleConnexion}
              isSmall={isSmallWidth}
            />
            <ToggleMenu
              open={menuOpen}
              onClose={this.toggleMenu}
              isSmall={isSmallWidth}
            />
            {/*{(position < 0 || !position) && <FloatingMenu />}*/}
            <FloatingMenu hide={!(position < -350 || !position)} />
            <main>{children}</main>
            {<Footer editCookies={true} />}
            <GAnalytics
              trackingId={process.env.GATSBY_G_TRACKING_ID}
              customTracker={tracker}
              currentUrl={currentUrl}
              optimizeId={process.env.GATSBY_G_OPTIMIZE_ID}
            />

            {/*
            {!hasCookieConsent && (
              <CookieBanner
                url={typeof window !== "undefined" && window.search}
                hidden={hasCookieConsent}
                policyUrl={"/politique-gestion-cookies/"}
                charteUrl={"/charte-protection-des-donnees/"}
                />
            )}
            <CookieModal
              show={editCookies}
              handleHide={this.hideCookieConsent}
              policyUrl={"/politique-gestion-cookies/"}
              charteUrl={"/charte-protection-des-donnees/"}
            />
            */}

            <CywycChat />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  titleSeo: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  keywordsSeo: PropTypes.array,
  description: PropTypes.string,
  tracker: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  home: PropTypes.bool,
  faq: PropTypes.bool,
}

export default Layout
