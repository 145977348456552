import React, { useEffect, useState } from "react"
import Switch from "react-switch"
import PropTypes from "prop-types"
import classNames from "classnames"

const CookieModalSwitch = ({
  title,
  onColor,
  offColor,
  checked,
  onChange,
  infoText,
  disabled,
  afterTextOnMobile,
}) => {
  const [checkedState, setCheckedState] = useState(false)

  useEffect(() => {
    setCheckedState(checked)
  }, [checked])

  const onCheckChange = checked => {
    setCheckedState(checked)
    onChange(checked)
  }

  const isIE11 =
    typeof navigator !== "undefined" &&
    /MSIE \d|Trident.*rv:/.test(navigator.userAgent)

  const checkedSwitchClasses = classNames("checked-switch-icon", {
    "is-ie": isIE11,
  })

  const uncheckedSwitchClasses = classNames("unchecked-switch-icon", {
    "is-ie": isIE11,
  })

  const switchContainerClasses = classNames(
    "col-5 col-lg-2",
    { "order-lg-last": !afterTextOnMobile },
    { "order-last": afterTextOnMobile }
  )

  return (
    <div className="row py-2 px-3 bg-gray mt-1">
      <div className="col-7 col-lg-10 font-weight-bold" hidden={!title}>
        {title}
      </div>
      <div className={switchContainerClasses}>
        <Switch
          className="react-switch"
          onChange={onCheckChange}
          checked={checkedState}
          onColor={onColor}
          offColor={offColor}
          disabled={disabled}
          width={110}
          checkedIcon={<div className={checkedSwitchClasses}>Autorisé</div>}
          uncheckedIcon={<div className={uncheckedSwitchClasses}>Refusé</div>}
        />
      </div>
      <div className="col-12 col-lg-10 text-justify mt-2">{infoText}</div>
    </div>
  )
}

CookieModalSwitch.propTypes = {
  title: PropTypes.string.isRequired,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  infoText: PropTypes.node,
  disabled: PropTypes.bool,
}

CookieModalSwitch.defaultProps = {
  onColor: "#28A745",
  offColor: "#DC3545",
  checked: false,
  onChange: () => {},
  disabled: false,
}

export default CookieModalSwitch
