import React from "react";
import logoMutualiteFrancaise from "../images/Logo-Mutualite-Francaise.png";
import { ATInternetTagService } from './../services/global/ATInternetService';

const Footer = ({ editCookies }) => (
    <div>
        <footer className="footer">
            <div className="footerSecond">
                <div className="container ptm">
                    <div className="row">
                        <div className="col-sm-6 col-md-3">
                            <div className="pll">
                                <span>Mutuelle mgc</span>
                                <ul>
                                    <li><a href="/la-mutuelle-mgc/">Qui sommes-nous ?</a></li>
                                    <li><a href="/la-mutuelle-mgc/mgc-et-sncf/">La MGC et la SNCF</a></li>
                                    <li><a href="/la-mutuelle-mgc/partenaires/">Nos partenaires</a></li>
                                    <li><a href="/contact/">Contacter la MGC</a></li>
                                    <li><a href="/agences/">Nos agences</a></li>
                                    <li><a rel="noopener noreferrer" href="https://www.groupemgc.fr/nos-offres-d-emploi/" target="_blank">Recrutement</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="pll">
                                <span>Nos solutions</span>
                                <ul>
                                    <li><a href="/mutuelle-sante/devis/">Demande de devis</a></li>
                                    <li><a href="/mutuelle-sante/adhesion-en-ligne/">Adhésion en ligne</a></li>
                                    <li><a href="/mutuelle-sante/">Complémentaire santé</a></li>
                                    <li><a href="/entreprise/">Mutuelle entreprise</a></li>
                                    <li><a href="/surcomplementaire-sante/">Surcomplémentaire</a></li>
                                    <li><a href="/mutuelle-prevoyance/">Prévoyance</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="pll">
                                <span>Vous êtes ?</span>
                                <ul>
                                    <li><a href="/cheminot-en-activite/">Agent SNCF </a> | <a href="/cheminot-retraite/">Retraité&nbsp;SNCF</a></li>
                                    <li><a href="/particulier/">Particulier - de 60 ans</a> | <a href="/senior/">60&nbsp;ans&nbsp;et&nbsp;+</a></li>
                                    <li><a href="/agent-territorial/">Agent territorial</a></li>
                                    <li><a href="/collectivite-territoriale/">Collectivité territoriale</a></li>
                                    <li><a href="/professionnel-independant/">Professionnel Indépendant</a></li>
                                    <li><a href="/entreprise/">Entreprise</a> | <a href={'/entreprise-syntec/'}>Entreprise&nbsp;SYNTEC</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="pll">
                                <span>Espaces dédiés</span>
                                <ul>
                                    <li><a href="https://monespacepersonnel.cimut.net/web/mgc">Espace adhérent</a></li>
                                    <li><a href="https://monespacepersonnel.cimut.net/web/mgc-entreprise">Espace employeur</a></li>
                                    <li><a href="https://monespacepersonnel.cimut.net/web/mgc-partenaire">Espace professionnel de santé</a></li>
                                    <li><a href="https://monespacepersonnel.cimut.net/web/mgc-courtage">Espace courtier</a></li>
                                    <li><a href="https://groupemgc.sharepoint.com/sites/PassSections">Espace comité local</a></li>
                                    <li><a href="/espace-resiliation/">Espace résiliation</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerThird">
                <div className="container ptl pbl">
                    <div className="footerThirdFlex">
                        <div className="footerThirdFlexLogo">
                            <img src={logoMutualiteFrancaise} alt="" />
                        </div>
                        <div className="footerThirdFlexColumn">
                            <div className="footerThirdFlexNav">
                                <ul>
                                    <li><a href="/contact/">Contact</a> -</li>&nbsp;
                                    <li><a href="/faq/">FAQ</a> -</li>&nbsp;
                                    <li><a href="/mentions-legales/">Mentions légales</a> -</li>&nbsp;
                                    <li><a href="/charte-protection-des-donnees/">Protection des données</a> -</li>&nbsp;
                                    <li><a href="/politique-gestion-cookies/">Politique de gestion des cookies</a></li>&nbsp;
                                </ul>
                            </div>
                            <div className="footerThirdFlexMentions">
                                <p>Depuis 1883 la Mutuelle Générale des Cheminots protège les agents SNCF et leur famille. Depuis 2006, la MGC est ouverte à tous, cheminots ou non !<br />© Mutuelle MGC - Réalisé par IOcean</p>
                            </div>
                        </div>

                        <div className="footerThirdFlexLinks tar">
                            <a href="https://www.facebook.com/mutuelle.mgc" target="_blank" onClick={() => ATInternetTagService.sendExitClick({click:'facebook',click_chapter2:'Reseaux_sociaux'})}>
                                <i className="icon-facebook-circle"></i></a>
                            <a href="https://twitter.com/groupeMGC" target="_blank" onClick={() => ATInternetTagService.sendExitClick({click:'twitter',click_chapter2:'Reseaux_sociaux'})}>
                                <i className="icon-twitter-circle"></i></a>
                            <a href="https://www.linkedin.com/company/groupe-mgc" target="_blank" onClick={() => ATInternetTagService.sendExitClick({click:'linkedin',click_chapter2:'Reseaux_sociaux'})}>
                                <i className="icon-linkedin-circle"></i></a>
                            <a href="https://www.youtube.com/user/MGCFILMS" target="_blank" onClick={() => ATInternetTagService.sendExitClick({click:'youtube',click_chapter2:'Reseaux_sociaux'})}>
                                <i className="icon-youtube-circle"></i></a>
                        </div>

                        <div className="footerThirdFlexMentionsMobile">
                            <p>Depuis 1883 la Mutuelle Générale des Cheminots protège les agents SNCF et leur
                                famille.Depuis 2006, la MGC est ouverte à tous, cheminots ou non !<br/>© Mutuelle MGC -
                                Réalisé par IOcean</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
)

export default Footer
