class UrlService {
  static addSlashToEndOfUrl(url) {
    return !!url && url.slice(-1) !== '/' ? `${url}/` : url
  }

  static addSlashToStartOfUrl(url) {
    return !!url && url.slice(0, 1) !== "/" ? `/${url}` : url
  }

  static addSlashAroundUrl(url) {
    return this.addSlashToEndOfUrl(this.addSlashToStartOfUrl(url))
  }
}

module.exports = UrlService;