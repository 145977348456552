import React, { useEffect } from "react"
import PropTypes from "prop-types"
import GAnalyticsService from "../../services/gAnalyticsService"
import { acceptedAudience } from "../../services/global/cookiesService"

const GAnalytics = ({ trackingId, optimizeId, customTracker, currentUrl }) => {
  useEffect(() => {
    const windowGlobal = typeof window !== "undefined" && window
    if (trackingId && windowGlobal /*&& acceptedAudience()*/) {
      GAnalyticsService.initialize(trackingId, optimizeId)
    }
  }, [trackingId, optimizeId])

  useEffect(() => {
    const windowGlobal = typeof window !== "undefined" && window
    if (trackingId && windowGlobal /*&& acceptedAudience()*/) {
      const tracker = customTracker || currentUrl
      tracker && GAnalyticsService.sendPageviw(tracker)
    }
  }, [currentUrl, customTracker])

  return null
}

GAnalytics.propTypes = {
  trackingId: PropTypes.string,
  optimizeId: PropTypes.string,
  customTracker: PropTypes.string,
  currentUrl: PropTypes.string,
}
GAnalytics.defaultProps = {
  currentUrl: "",
}

export default GAnalytics
