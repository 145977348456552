import Cookies from "universal-cookie"
import Axios from "axios"
import { v4 as uuidv4 } from 'uuid';

const cookies = new Cookies()
const EXPIRES = 31557600
const COOKIE_NAME = "cookies-consent"
const COOKIE_UUID_NAME = "cookies-uuid-consent"
const ADS_COOKIES_NAME = ["__gads", "__gac","_uetvid","_uetsid"]
const AUDIENCE_COOKIES_NAME = ["_ga","_gid", "_gat"]
const NB_MONTHS_BEFORE_COOKIES_CONSENT = 6
const COOKIE_LASTUPDATECONSENT_NAME = "cookies-lastupdate-consent"

export const acceptAllCookies = () => {
  return setCookiesConsent(true, true, true,acceptedOptout())
}

export const refuseAllCookies = () => {
    let optout = acceptedOptout()
    deleteAdCookies()
    deleteAudienceCookies()
    return setCookiesConsent(false, false, false,optout)
}

export const setCookiesConsent = (audience, ads, socialNetwork, optout) => {

  const URL = `${process.env.GATSBY_CMS_API_URL}/api/consent`
  
  let value = "1"
  audience ? (value += "1") : (value += "0")
  ads ? (value += "1") : (value += "0")
  socialNetwork ? (value += "1") : (value += "0")
  optout ? (value += "1") : (value += "0")

  let actionConsent = "CREATE"
  if(cookies.get(COOKIE_NAME)){
    actionConsent = "UPDATE"
  }

  var dateExpire = new Date(Date.now());
  dateExpire.setMonth(dateExpire.getMonth() + NB_MONTHS_BEFORE_COOKIES_CONSENT)

  cookies.set(COOKIE_NAME, value, {
    path: "/",
    expires: dateExpire
  })

  let uuid
  if(cookies.get(COOKIE_UUID_NAME)){
    uuid = cookies.get(COOKIE_UUID_NAME)
  }else{
    uuid = uuidv4()
  }

  let consentJSON = "cookies techniques : 1";
  consentJSON = consentJSON + ", cookies mesure audience : ";
  if(audience){
    consentJSON = consentJSON  + "1";
  }else{
    consentJSON = consentJSON  + "0";
  }

  consentJSON = consentJSON + ", cookies publicitaires : ";
  if(ads){
    consentJSON = consentJSON  + "1";
  }else{
    consentJSON = consentJSON  + "0";
  }  

  consentJSON = consentJSON + ", cookies de reseaux sociaux : ";
  if(socialNetwork){
    consentJSON = consentJSON  + "1";
  }else{
    consentJSON = consentJSON  + "0";
  }

  consentJSON = consentJSON + ", optout AT INTERNET : ";
  if(optout){
    consentJSON = consentJSON  + "1";
  }else{
    consentJSON = consentJSON  + "0";
  }
  
  cookies.set(COOKIE_UUID_NAME, uuid, {
    path: "/",
    expires: dateExpire
  })

  let lastupdateconsent = new Date(Date.now());
  cookies.set(COOKIE_LASTUPDATECONSENT_NAME, lastupdateconsent, {
    path: "/",
    expires: dateExpire,
  })

  //mettre le tag update
  if(audience){
    //console.log('setCookiesConsent analytics_storage : granted');
    /*window.gtag('consent', 'update', {
      'analytics_storage': 'granted'
    });
    */
  }else{
    //console.log('setCookiesConsent analytics_storage : denied');
    /*window.gtag('consent', 'update', {
      'analytics_storage': 'denied'
    });
    */
  }

  Axios.post(
    URL,
    {
      uuid : uuid,
      action : actionConsent,
      consent : consentJSON
    }
  ).then(result => {
    })

  return cookies;
}

export const getCookiesConsent = () => {
  return cookies.get(COOKIE_NAME)
}

export const acceptedAudience = () => {
  const consentValue = getCookiesConsent()
  return !!consentValue && consentValue.charAt(1) === "1"
}

export const acceptedAds = () => {
  const consentValue = getCookiesConsent()
  return !!consentValue && consentValue.charAt(2) === "1"
}

export const acceptedSocialNetwork = () => {
  const consentValue = getCookiesConsent()
  return !!consentValue && consentValue.charAt(3) === "1"
}

export const acceptedOptout = () => {
  const consentValue = getCookiesConsent()
  let ret = !!consentValue && consentValue.length > 4 && consentValue.charAt(4) === "1"
  return ret;
}

export const deleteCookiesConsent = () => {
  cookies.remove(COOKIE_NAME, { path: "/" })
}

export const hasCookieConsent = () => {
  //mettre le gtage par defaut
  if(!getCookiesConsent()){
    //console.log('consent default denied');
    /*
    window.gtag('consent', 'default', {
      'analytics_storage': 'denied'
    });
    */


  }
  return !!getCookiesConsent()
}

export const deleteAdCookies = () => {
  ADS_COOKIES_NAME.forEach(name => {
    console.log(name);
    //cookies.set(name, { path: "/", expires: new Date('September 22, 2018 15:00:00') });
    const cookAd = cookies.get(name);
    if(cookAd){
      cookies.remove(name, { path: "/" })
    }
  })
}

export const deleteAudienceCookies = () => {
  AUDIENCE_COOKIES_NAME.forEach(name => {
    console.log(name);
    //cookies.set(name, { path: "/", expires: new Date('September 22, 2018 15:00:00') });
    //cookies.remove(name, { path: "/" })
    const cookAdu = cookies.get(name);
    if(cookAdu){
      cookies.remove(name, { path: "/" })
    }
  })
}

export const getLastUpdateConsent = () => {
  var lastUpdateConsent = cookies.get(COOKIE_LASTUPDATECONSENT_NAME);
  return lastUpdateConsent
}
