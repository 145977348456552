import jwtDecode from "jwt-decode"
import Cookies from "universal-cookie"

export class MgcgestionTokenService {
  static TOKEN_KEY = "auth-token"

  static EXPIRES = 7200000 // 2h en ms

  static cookies = new Cookies()

  static storeToken(token) {
    if (typeof window !== "undefined") {
      this.removeToken()
      this.cookies.set(this.TOKEN_KEY, token, {
        path: "/",
        expires: new Date(Date.now() + this.EXPIRES),
      })
    }
  }

  static getToken() {
    if (typeof window !== "undefined") {
      const authToken = this.cookies.get(this.TOKEN_KEY)
      if (!!authToken) {
        return `Bearer ${authToken}`
      }
    }

    return null
  }

  static getConfig() {
    const token = this.getToken()
    return !!token && { headers: { authorization: token } }
  }

  static removeToken() {
    if (typeof window !== "undefined") {
      localStorage.removeItem("jwtToken")
      localStorage.removeItem("jwtToken-expires")
      this.cookies.remove(this.TOKEN_KEY, { path: "/" })
    }
  }

  static authentified() {
    return typeof window !== "undefined" && this.cookies.get(this.TOKEN_KEY)
  }

  static getAuthEmail() {
    return this.authentified() ? this.getEmalFromToken(this.getToken()) : null
  }
  static getEmalFromToken(token) {
    const decoded = jwtDecode(token)
    return decoded ? decoded.sub : null
  }
  static getMgcAdminConfig() {
    return { headers: { authorization: `${this.getToken()}` } }

  }
}
