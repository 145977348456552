import React, { useState } from "react"
import threeMembers from "../images/three_members.png"
import threeMembersHover from "../images/three_members_hover.png"
import member from "../images/member.png"
import memberHover from "../images/member_hover.png"
import building from "../images/building.png"
import heart from "../images/heart.png"
import buildingHover from "../images/building-hover.png"
import heartHover from "../images/heart-hover.png"
import seats from "../images/seats.png"
import seatsHover from "../images/seats_hover.png"
import { ATInternetTagService } from './../services/global/ATInternetService';
import { setNestedObjectValues } from "formik"

const ToggleConnection = ({ open, onClose, isSmall }) => {
  let marginRight = "0"
  if (open) {
    marginRight = isSmall ? "562px" : "495px"
  }
  const style = {
    marginRight: marginRight,
  }

  const [srcThreeMembers, setSrcThreeMembers] = useState(threeMembers)
  const [srcMember, setSrcMember] = useState(member)
  const [srcBuilding, setSrcBuilding] = useState(building)
  const [srcHeart, setSrcHeart] = useState(heart)
  const [srcSeats, setSrcSeats] = useState(seats)
  
  return (
    <div className="sideconnexion" style={style}>
      <div className="container">
        <div className="content">
          <div className="row">
            <div className="col-12 padding-0">
              <div className="title-block" onClick={onClose}>
                J'accède à mon espace personnel
                <i
                  className="icon-close hideConnection mr-auto"
                />
              </div>
            </div>
            <div className="col-12 padding-0">
              <div
                className="block"
                onMouseEnter={() => setSrcThreeMembers(threeMembersHover)}
                onMouseLeave={() => setSrcThreeMembers(threeMembers)}
              >
                <a href="https://monespacepersonnel.cimut.net/web/mgc" onClick={() => ATInternetTagService.sendNavClick({click:'Espace_adherent'})}>
                  <img src={srcThreeMembers} alt="Espace adhérent" />
                  <div className="title">Espace adhérent ></div>
                </a>
              </div>
            </div>
            <div className="col-6 padding-0">
              <div
                className="block"
                onMouseEnter={() => setSrcMember(memberHover)}
                onMouseLeave={() => setSrcMember(member)}
              >
                <a href="https://monespacepersonnel.cimut.net/web/mgc-courtage" onClick={() => ATInternetTagService.sendNavClick({click:'Espace_courtier'})}>
                  <img src={srcMember} alt="Espace courtier" />
                  <div className="title">Espace courtier ></div>
                </a>
              </div>
            </div>
            <div className="col-6 padding-0">
              <div
                className="block"
                // A DECOMMENTER
                onMouseEnter={() => setSrcBuilding(buildingHover)}
                onMouseLeave={() => setSrcBuilding(building)}
              >
                <a href="https://monespacepersonnel.cimut.net/web/mgc-entreprise" onClick={() => ATInternetTagService.sendNavClick({click:'Espace_employeur'})}>
                  <img src={srcBuilding} alt="Espace employeur" style={{height: '64px'}}/>
                  <div className="title">Espace employeur ></div>
                </a>
              </div>
            </div>
            <div className="col-6 padding-0">
              <div
                className="block"
                // A DECOMMENTER
                onMouseEnter={() => setSrcHeart(heartHover)}
                onMouseLeave={() => setSrcHeart(heart)}
              >
                <a href="https://monespacepersonnel.cimut.net/web/mgc-partenaire" onClick={() => ATInternetTagService.sendNavClick({click:'Espace_professionnel_de_sante'})}>
                  <img src={srcHeart} alt="Espace professionnel de santé" style={{height: '30px'}}/>
                  <div className="title">Espace professionnel de santé ></div>
                </a>
              </div>
            </div>
            <div className="col-6 padding-0">
              <div
                className="block"
                onMouseEnter={() => setSrcSeats(seatsHover)}
                onMouseLeave={() => setSrcSeats(seats)}
              >
                <a href="https://groupemgc.sharepoint.com/sites/PassSections" onClick={() => ATInternetTagService.sendNavClick({click:'Espace_comite_local'})}>
                  <img src={srcSeats} alt="Espace comité local" />
                  <div className="title">Espace comité local ></div>
                </a>
              </div>
            </div>
            <div className="col-12 padding-0">
              <div className="block">
                <a
                  className="adhesion"
                  href={"/mutuelle-sante/adhesion-en-ligne/"} onClick={() => ATInternetTagService.sendNavClick({click:'reprise_adhesion'})}
                >
                  Reprendre une adhésion en cours
                </a>
              </div>
            </div>
            <div className="col-12 padding-0">
              <div className="container">
                <div className="sideconnexionButton">
                  <div className="row text-center">
                    <div className="col-12 col-sm-8 offset-sm-2 my-1 col-md-6 offset-md-0 padding-0">
                      <a
                        className="buttonDevis"
                        href={"/mutuelle-sante/devis/"} onClick={() => ATInternetTagService.sendNavClick({click:'demande_de_devis'})}
                      >
                        Demande de devis
                      </a>
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 my-1 col-md-6  offset-md-0 padding-0">
                      <a
                        className="buttonAdhesion"
                        href={"/mutuelle-sante/adhesion/"} onClick={() => ATInternetTagService.sendNavClick({click:'adhesion'})}
                      >
                        Adhésion
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToggleConnection
