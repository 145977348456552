import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

const CookieModalTextInfo = ({text, textSecondary}) => {
  const [hideSecondary, setHideSecondary] = useState(textSecondary)
  return (
    <div>
      {text} &nbsp;
      <a
        className={"know-more"}
        onClick={() => {
          setHideSecondary(false)
        }}
        hidden={!hideSecondary}
      >
        En savoir plus
      </a>
      <div hidden={hideSecondary} className={"gray"}>{textSecondary}</div>
    </div>
  )
}

CookieModalTextInfo.propTypes = {
  text: PropTypes.string.isRequired,
  textSecondary: PropTypes.node.isRequired,
}

export default CookieModalTextInfo