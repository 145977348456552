//import ReactGA from "react-ga"
import { acceptedAudience } from "../services/global/cookiesService"
import { getCookiesConsent } from "../services/global/cookiesService"


export default class GAnalyticsService {
  static ENABLED = false

  static initialize(trackingId, optimizeId) {
    if (this.ENABLED) {

      console.log('ga trackingId : ' + trackingId);
      console.log('ga optimizeId : ' + optimizeId);

      window.gtag('js', new Date());
      window.gtag('consent', 'default', {
        'analytics_storage': 'denied'
      });

      //console.log(getCookiesConsent());
      if(acceptedAudience()){
        //console.log('acceptedAudience acceptedAudience acceptedAudience acceptedAudience');    
        window.gtag('consent', 'update', {
          'analytics_storage': 'granted'
        });  
      }else{
        //console.log('no acceptedAudience'); 
      }

      if (!!optimizeId) {
        window.gtag('config', trackingId, {'optimize_id': optimizeId, 'send_page_view': false, 'anonymize_ip': true});
      //  window.gtag('config', trackingId);
      }else{
        window.gtag('config', trackingId, {'send_page_view': false, 'anonymize_ip': true});
      }
    }
  }

  static sendPageviw(url) {
    /*
  //  this.ENABLED && ReactGA.pageview(url)
  console.log('event page_view page_path : ' + url)
// 57155
  this.ENABLED && 
    window.gtag('event', 'page_view', {
      page_path: url
    })

    var gg = window.gtag('event', 'page_view', {
      page_path: url + 'toto'
    })
    console.log(gg)
//57155
*/
  }

  static sendEvent(category, action, label, value = null) {
/*
    this.ENABLED &&
      ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: value,
      })

    //console.log('event :' + action + 'event_category : ' + category + ' event_label : ' + label + ' value : ' + value); 
  //ticket 57155
    this.ENABLED && 
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value
    })
    */
  }

  static sendPageviewAfterBanner(trackingId, optimizeId) {
  /*  if (typeof window !== "undefined") {
      const currentUrl =
        typeof window !== "undefined" &&
        window &&
        window.location.pathname + window.location.search

      //console.log('sendPageviewAfterBanner : ' + currentUrl)
      
      this.initialize(trackingId, optimizeId)
      this.sendPageviw(currentUrl)
    }
  */
  }

}
