import React from "react"
import classNames from "classnames"
import "./floatingMenu.css"
import { ATInternetTagService } from './../services/global/ATInternetService';

const FloatingMenu = ({hide}) => {
  const floatingMenuClasses = classNames("floatingMenu", {
    "block" : !hide
  })
  return (
      <div className={floatingMenuClasses}>
        <ul className="floatingMenuBloc">
          <li className="floatingMenuBlocItem"><a href="/mutuelle-sante/devis/"><i className="icon-euro" 
          onClick={() => ATInternetTagService.sendNavClick({click:'devis'})}></i>Devis</a></li>
          <li className="floatingMenuBlocItem"><a href="/faq/" 
          onClick={() => ATInternetTagService.sendNavClick({click:'FAQ'})}><i className="icon-question"></i>Faq</a></li>
          <li className="floatingMenuBlocItem"><a href="/contact/"><i className="icon-email" 
          onClick={() => ATInternetTagService.sendNavClick({click:'contact'})}></i>Contact</a></li>
          <li className="floatingMenuBlocItem"><a href="/agences/" 
          onClick={() => ATInternetTagService.sendNavClick({click:'agences'})}><i className="icon-location"></i>Agences</a></li>
        </ul>
        {
        <ul className="floatingMenuBloc">
          <li className="floatingMenuBlocItem callback"><a className={"webcallback-link LnkWcbForm-trigger"} id="webcallback-trigger"><i className="icon-phone"/>Rappel immédiat</a></li>
        </ul>
        }
      </div>
  )
}


export default FloatingMenu
